import React from "react";
import styled from "@emotion/styled";
import { keyframes } from "@emotion/core";
import { css } from "@emotion/core";
import Circle from "@media/cta/circle.svg";
import CloudL from "@media/cta/cloud_l.svg";
import CloudR from "@media/cta/cloud_r.svg";
import BottomImage from "@media/cta/cta-bottom.svg";

const spin = keyframes`
  from {
    transform:rotate(0deg);
  }
  to {
    transform:rotate(360deg);
  }
`;

const Container = styled.div`
  position: relative;
  background-image: url(${BottomImage});
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  height: 300px;
`;

const Content = styled.div`
  text-align: center;

  h3 {
    color: #fff;
    font-weight: 300;
    font-size: 1.5em;
  }
`;

const Cloud1 = styled.img`
  position: absolute;
  top: 30px;
  left: -250px;
  width: 500px;
`;

const Cloud2 = styled.img`
  position: absolute;
  top: 50px;
  right: -140px;
  width: 280px;
`;

const CircleContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 60%);
  width: 500px;
`;

const CircleSpin = styled.img`
  display: block;
  width: 100%;
  animation-name: ${spin};
  animation-duration: 200s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
`;

const Button = styled.a`
  position: relative;
  z-index: 1;
  display: inline-block;
  border-radius: 5px;
  background: rgb(15, 161, 248);
  background: linear-gradient(
    0deg,
    rgba(15, 161, 248, 1) 0%,
    rgba(25, 178, 255, 1) 100%
  );
  border: none;
  outline: none;
  cursor: pointer;
  color: #fff;
  padding: 12px 22px;
  text-decoration: none;
  font-weight: 300;
  transform: translateY(0);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);

  &:hover {
    transform: translateY(-1px);
  }
`;

const CTA = () => (
  <Container>
    <Content className="pt4">
      <h3 className="mb2" css={css`
        font-size: 2em !important;
      `}>Let's Connect!</h3>
      <Button href="mailto:hello@cinesend.com">Contact Us</Button>
    </Content>

    <Cloud1 src={CloudL} />
    <Cloud2 src={CloudR} />
    <CircleContainer>
      <CircleSpin src={Circle} />
    </CircleContainer>
  </Container>
);

export default CTA;
