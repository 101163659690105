import React, { useState } from "react";
import PropTypes from "prop-types";
import { css } from "@emotion/core";
import { UnmountClosed } from "react-collapse";
import arrow from "@media/icons/nav/arrow.svg";
import Anchor from "./anchor";

const Row = ({ link, toggle }) => {
  const [open, toggleOpen] = useState(false);
  return (
    <React.Fragment>
      {link.children ? (
        <React.Fragment>
          <button
            className="py1 flex items-center justify-between pointer"
            css={css`
              border-top-right-radius: 3px;
              border-top-left-radius: 3px;
              border-bottom-right-radius: ${open ? "0px" : "3px"};
              border-bottom-left-radius: ${open ? "0px" : "3px"};
              padding: 0em 0.5em;
              background: ${open ? "#ededed" : "#ffffff"};
              width: 100%;
              height: 2.5em;
              color: inherit;
              font-weight: normal;
              transform: none !important;
              :hover,
              :focus {
                background: #ededed;
              }
            `}
            onClick={() => toggleOpen(!open)}
          >
            {link.label}
            <img
              src={arrow}
              alt="Arrow"
              className="ml2"
              css={css`
                width: 10px;
                transform: rotate(${open ? "180deg" : "0deg"});
                transition: all 0.2s linear;
                opacity: 0.7;
              `}
            />
          </button>
          <UnmountClosed
            isOpened={open}
            css={css`
              background: ${open ? "#ededed" : ""};
              border-bottom-left-radius: 3px;
              border-bottom-right-radius: 3px;
            `}
          >
            <div className="pb1">
              {link.children.map(child =>
                child.links
                  .filter(c => !c.hide)
                  .map((cld, index) => <Anchor link={cld} key={index} />)
              )}
            </div>
          </UnmountClosed>
        </React.Fragment>
      ) : (
        <Anchor link={link} toggle={toggle} />
      )}
    </React.Fragment>
  );
};

Row.propTypes = {
  link: PropTypes.object.isRequired,
  toggle: PropTypes.func.isRequired
};

export default Row;
