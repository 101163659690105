import React from "react";
import PropTypes from "prop-types";
import CTA from "./cta";
import Base from "./base/";

const pagesToHideCTA = ["contact", "demo"];

const Footer = ({ page, ...rest }) => (
  <React.Fragment>
    {!pagesToHideCTA.includes(page) && <CTA {...rest} />}
    <Base />
  </React.Fragment>
);

Footer.propTypes = {
  getStartedText: PropTypes.string.isRequired,
  getStartedButtons: PropTypes.array.isRequired,
  page: PropTypes.string.isRequired
};

export default Footer;
