import React from "react";
import PropTypes from "prop-types";
import { css } from "@emotion/core";
import whiteMenu from "@media/icons/nav/menu_white.svg";
import darkMenu from "@media/icons/nav/menu_dark.svg";

const Open = ({ open, pinned }) => (
  <a
    href=""
    title="View Menu"
    css={css`
      width: 32px;
      height: 32px;
      border-radius: 50%;
      :hover,
      :focus {
        background: #fb0f3b;
      }
    `}
    className="flex items-center justify-center circle"
    onClick={open}
  >
    <img
      alt="Menu"
      src={pinned ? darkMenu : whiteMenu}
      css={css`
        width: 18px;
        height: 18px;
      `}
    />
  </a>
);

Open.propTypes = {
  open: PropTypes.func.isRequired,
  pinned: PropTypes.bool.isRequired
};

export default Open;
