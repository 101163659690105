import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import { css as c } from "emotion";
import { css, keyframes } from "@emotion/core";
import { useOutsideClick, useKeyPress } from "react-use";
import Link from "gatsby-link";
import Seat from "@media/aero/seat.svg";
import Content from "@media/aero/content-services.svg";
import IFE from "@media/aero/ife_lab.svg";
import Ground from "@media/aero/on_the_ground.svg";

const icons = {
  seat: Seat,
  ife: IFE,
  ground: Ground,
  content: Content
};

const fade = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const Dropdown = ({ link, pinned }) => {
  const [isOpen, toggle] = useState(false);
  const dropdownRef = useRef();
  const close = () => {
    if (isOpen) {
      toggle(false);
    }
  };
  const open = e => {
    e.preventDefault();
    toggle(!isOpen);
  };
  useOutsideClick(dropdownRef, () => close());
  if (useKeyPress("Escape") && isOpen) {
    close();
  }
  return (
    <div ref={dropdownRef} className="relative">
      <a
        href=""
        css={css`
          color: ${pinned ? "#383E43" : "#ffffff"} !important;
          font-size: 16px;
          font-weight: ${isOpen ? "bold" : "normal"};
        `}
        className="flex items-center"
        onClick={open}
      >
        {link.label}
        <span
          className="material-icons ml1"
          css={css`
            transition: transform 0.2s linear;
            transform: rotate(${isOpen ? "180deg" : "0deg"});
          `}
        >
          keyboard_arrow_down
        </span>
      </a>
      {isOpen && (
        <div
          className="pt2 absolute top-100"
          css={css`
            left: 50%;
            transform: translateX(-50%);
            opacity: 0;
            animation: ${fade} 0.2s linear forwards;
            width: 400px;
          `}
        >
          <div
            className="bg-white absolute"
            css={css`
              left: calc(50% - 6px);
              width: 12px;
              height: 12px;
              top: 12px;
              transform: rotate(45deg);
            `}
          />
          <div
            css={css`
              box-shadow: 0 6px 19px 0 rgba(98, 98, 98, 0.59);
            `}
            className="bg-white px2 py1 rounded"
          >
            {link.children.map((child, index) => (
              <div key={index} className="">
                <div className="">
                  {child.links
                    .filter(cld => !cld.hide)
                    .map((dropdownLink, i) => (
                      <Link
                        onClick={e => {
                          if (dropdownLink.disabled) {
                            e.preventDefault();
                          }
                        }}
                        key={i}
                        to={dropdownLink.to}
                        activeClassName={c`
                        > div {
                          background: #f9f9f9;
                        }
                      `}
                        className="py1 rounded col-12 relative"
                        css={css`
                          :hover {
                            > div {
                              ${dropdownLink.disabled
                                ? ""
                                : "background: #f9f9f9"};
                              cursor: ${dropdownLink.disabled
                                ? "default"
                                : "pointer"};
                            }
                          }
                        `}
                      >
                        <div
                          css={css`
                            transition: all 0.2s linear;
                          `}
                          className="flex items-center p1 relative"
                        >
                          {dropdownLink.disabled && (
                            <div
                              css={css`
                                z-index: 1;
                                position: absolute;
                                top: 0;
                                left: -16px;
                                right: -16px;
                                bottom: -8px;
                                background: rgba(0, 0, 0, 0.05);
                              `}
                            ></div>
                          )}
                          {dropdownLink.icon && (
                            <img
                              src={icons[dropdownLink.icon]}
                              css={css`
                                width: 50px;
                              `}
                            />
                          )}
                          <div className="flex-auto pl2">
                            <strong
                              css={css`
                                margin-bottom: -0.25em;
                              `}
                              className="block"
                            >
                              {dropdownLink.label}
                            </strong>
                            {dropdownLink.description && (
                              <p className="muted mb0">
                                {dropdownLink.description}
                              </p>
                            )}
                          </div>
                        </div>
                      </Link>
                    ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

Dropdown.propTypes = {
  link: PropTypes.object.isRequired,
  pinned: PropTypes.bool.isRequired
};

export default Dropdown;
