import React, { useState } from "react";
import PropTypes from "prop-types";
import Link from "gatsby-link";
import getLoginURL from "@helpers/get_login_url";
import links from "@data/header/links.json";
import { OutboundLink } from "gatsby-plugin-google-analytics";
import Dropdown from "./dropdown";

const Desktop = ({ dark, pinned }) => {
  const login_url = getLoginURL();
  return (
    <div className="flex-auto xs-hide sm-hide md-flex items-center justify-end">
      {links.links
        .filter(link => link.show.includes("DESKTOP"))
        .map(link => (
          <div key={link._id} className="mr3 px1">
            {link.children ? (
              <Dropdown link={link} pinned={pinned} />
            ) : link.internal ? (
              <Link
                to={link.to}
                style={{
                  color: pinned ? "#383E43" : "#ffffff",
                  fontSize: "16px"
                }}
              >
                {link.label}
              </Link>
            ) : (
              <OutboundLink
                href={link.to.replace("{{LOGIN}}", login_url)}
                style={{
                  color: pinned ? "#383E43" : "#ffffff",
                  fontSize: "16px"
                }}
                rel="noopener noreferrer"
                target="_blank"
              >
                {link.label}
              </OutboundLink>
            )}
          </div>
        ))}
      {/* <OutboundLink
        target="_blank"
        className="ml1"
        rel="noopener noreferrer"
        href="https://accounts.cinesend.com/signup">
        <button tabIndex="-1" style={{ width: "110px" }} className={`small ${pinned ? "" : "web_white"}`}>
          <div style={{ color: dark && !pinned ? "#222" : "" }}>Sign Up</div>
        </button>
      </OutboundLink> */}
    </div>
  );
};

Desktop.propTypes = {
  dark: PropTypes.bool.isRequired,
  pinned: PropTypes.bool.isRequired
};

export default Desktop;
