import React from "react";
import PropTypes from "prop-types";
import Link from "gatsby-link";
import { css } from "@emotion/core";
import { OutboundLink } from "gatsby-plugin-google-analytics";
import Icon from "@app-components/utils/icon";

const Anchor = ({ link, toggle }) =>
  link.internal ? (
    <Link
      to={link.to}
      onClick={toggle}
      css={css`
        :hover,
        :focus {
          background: #ededed;
        }
      `}
      className="p1 flex items-center pointer rounded"
    >
      {link.icon && <Icon icon={link.icon} size={28} className="mr2" />}
      {link.label}
    </Link>
  ) : (
    <OutboundLink
      href={link.to}
      target="_blank"
      rel="noopener noreferrer"
      css={css`
        :hover,
        :focus {
          background: #ededed;
        }
      `}
      className="p1 flex items-center pointer rounded"
    >
      {link.icon && <Icon icon={link.icon} size={28} className="mr2" />}
      {link.label}
    </OutboundLink>
  );

Anchor.defaultProps = {
  toggle: null
};

Anchor.propTypes = {
  link: PropTypes.object.isRequired,
  toggle: PropTypes.func
};

export default Anchor;
