import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import metadata from "@data/metadata.json";
import metaImage from "@media/meta/aero_thumb.png";

const PageTitle = ({ page }) => (
  <Helmet
    title={metadata[page] ? metadata[page].title : "ERROR"}
    htmlAttributes={{ lang: "en" }}
    meta={[
      {
        name: "description",
        content: metadata[page] ? metadata[page].description : "ERROR"
      },
      {
        name: "keywords",
        content: metadata[page] ? metadata[page].keywords : "ERROR"
      },
      { name: "og:url", content: metaImage },
      { name: "og:type", content: "website" },
      { name: "fb:app_id", content: "1561710817415396" },
      {
        name: "og:title",
        content:
          "CineSend Aero | Reinventing passenger entertainment in the air and on the ground"
      },
      {
        name: "og:description",
        content:
          "CineSend is a leading provider of content delivery services across the film and television industry, and CineSend Aero is now ready for takeoff."
      },
      { name: "og:image", content: metaImage },
      { name: "og:image:secure_url", content: metaImage },
      { name: "og:image:type", content: "image/jpeg" },
      { name: "og:site", content: "@cinesend" },
      { name: "og:creator", content: "@cinesend" },
      { name: "twitter:card", content: "summary_large_image" },
      { name: "twitter:site", content: "@cinesend" },
      {
        name: "twitter:title",
        content:
          "CineSend Aero | Reinventing passenger entertainment in the air and on the ground"
      },
      {
        name: "twitter:description",
        content:
          "CineSend is a leading provider of content delivery services across the film and television industry, and CineSend Aero is now ready for takeoff."
      },
      { name: "twitter:image", content: metaImage },
      {
        name: "google-site-verification",
        content: "iQfUIYrtbVvb9xZMkJBekI2UPcJgykvy-SXh_9cMblk"
      }
    ]}
  />
);

PageTitle.propTypes = {
  page: PropTypes.string.isRequired
};

export default PageTitle;
