import React from "react";
import PropTypes from "prop-types";
import CineSendFiles from "@media/icons/products/files.svg";
import CineSendBookings from "@media/icons/products/bookings.svg";
import CineSendVenues from "@media/icons/products/venues.svg";
import CineSendFestivals from "@media/icons/products/festivals.svg";
import CineSendScreeners from "@media/icons/products/screeners.svg";
import CineSendDCPManager from "@media/icons/products/dcp-manager.svg";
import CineSendCSX from "@media/icons/products/csx.svg";
import CineSendBatchProcessor from "@media/icons/products/batch-processor.svg";
import CineSendFilesWhiteBg from "@media/icons/products/files-white-bg.svg";
import playWhite from "@media/icons/custom/white/play.svg";
import playBlack from "@media/icons/custom/black/play.svg";
import convertWhite from "@media/icons/custom/white/convert.svg";
import convertRed from "@media/icons/custom/red/convert.svg";
import sendWhite from "@media/icons/custom/white/send.svg";
import sendRed from "@media/icons/custom/red/send.svg";
import uploadWhite from "@media/icons/custom/white/upload.svg";
import uploadRed from "@media/icons/custom/red/upload.svg";
import usersWhite from "@media/icons/custom/white/users.svg";
import ccWhite from "@media/icons/custom/white/cc.svg";
import discWhite from "@media/icons/custom/white/disc.svg";
import subtitleWhite from "@media/icons/custom/white/subtitles.svg";
import asperaWhite from "@media/icons/custom/white/aspera.svg";
import movieWhite from "@media/icons/custom/white/movie.svg";
import watermarkWhite from "@media/icons/custom/white/watermark.svg";
import chartWhite from "@media/icons/custom/white/chart.svg";
import shieldWhite from "@media/icons/custom/white/shield.svg";
import dateWhite from "@media/icons/custom/white/date.svg";
import lockWhite from "@media/icons/custom/white/lock.svg";
import exportWhite from "@media/icons/custom/white/export.svg";
import urlWhite from "@media/icons/custom/white/url.svg";
import hdWhite from "@media/icons/custom/white/hd.svg";
import gridWhite from "@media/icons/custom/white/grid.svg";
import arrowsWhite from "@media/icons/custom/white/arrows.svg";
import fileWhite from "@media/icons/custom/white/file.svg";
import screenWhite from "@media/icons/custom/white/screen.svg";
import starWhite from "@media/icons/custom/white/star.svg";
import cartIcon from "@media/icons/custom/white/cart.svg";
import checkWhite from "@media/icons/custom/white/check.svg";
import auditWhite from "@media/icons/custom/white/audit.svg";
import rolesWhite from "@media/icons/custom/white/roles.svg";
import permissionsWhite from "@media/icons/custom/white/permissions.svg";
import formatsWhite from "@media/icons/custom/white/formats.svg";
import priceWhite from "@media/icons/custom/white/pricing.svg";
import mpaaWhite from "@media/icons/custom/white/mpaa.svg";
import twoFactorWhite from "@media/icons/custom/white/two_factor.svg";
import overviewRed from "@media/icons/custom/red/overview.svg";
import discoveryRed from "@media/icons/custom/red/discovery.svg";
import clockRed from "@media/icons/custom/red/clock.svg";
import ibcRed from "@media/icons/custom/red/ibc.svg";
import CampaignWizard from "@media/icons/custom/white/wizard.svg";
import Delivery from "@media/icons/custom/white/delivery.svg";
import KDM from "@media/icons/custom/white/kdm.svg";
import Vendor from "@media/icons/custom/white/vendor.svg";
import Settings from "@media/icons/custom/white/settings.svg";
import Download from "@media/icons/custom/white/download.svg";
import Folder from "@media/icons/custom/white/folder.svg";
import Hardware from "@media/icons/custom/white/hardware.svg";
import TrackUpload from "@media/icons/custom/white/track-upload.svg";
import usbWhite from "@media/icons/custom/white/usb.svg";
import printWhite from "@media/icons/custom/white/print.svg";
import securityWhite from "@media/icons/custom/white/security.svg";
import networkWhite from "@media/icons/custom/white/network.svg";

const ICONS = {
  "cinesend-files": CineSendFiles,
  "cinesend-bookings": CineSendBookings,
  "cinesend-venues": CineSendVenues,
  "cinesend-festivals": CineSendFestivals,
  "cinesend-screeners": CineSendScreeners,
  "cinesend-batch-processor": CineSendBatchProcessor,
  "cinesend-dcp-manager": CineSendDCPManager,
  "cinesend-files-white-bg": CineSendFilesWhiteBg,
  "cinesend-csx": CineSendCSX,
  "play-white": playWhite,
  "play-black": playBlack,
  "convert-white": convertWhite,
  "convert-red": convertRed,
  "send-white": sendWhite,
  "send-red": sendRed,
  "upload-white": uploadWhite,
  "upload-red": uploadRed,
  "users-white": usersWhite,
  "discs-white": discWhite,
  "cc-white": ccWhite,
  "subtitle-white": subtitleWhite,
  "aspera-white": asperaWhite,
  "watermark-white": watermarkWhite,
  "chart-white": chartWhite,
  "movie-white": movieWhite,
  "shield-white": shieldWhite,
  "date-white": dateWhite,
  "lock-white": lockWhite,
  "export-white": exportWhite,
  "url-white": urlWhite,
  "hd-white": hdWhite,
  "grid-white": gridWhite,
  "arrows-white": arrowsWhite,
  "file-white": fileWhite,
  "star-white": starWhite,
  "cart-white": cartIcon,
  "check-white": checkWhite,
  "roles-white": rolesWhite,
  "permissions-white": permissionsWhite,
  "audit-white": auditWhite,
  "formats-white": formatsWhite,
  "screen-white": screenWhite,
  "pricing-white": priceWhite,
  "two-factor-white": twoFactorWhite,
  "mpaa-white": mpaaWhite,
  "clock-red": clockRed,
  "discovery-red": discoveryRed,
  "overview-red": overviewRed,
  "ibc-red": ibcRed,
  "usb-white": usbWhite,
  "security-white": securityWhite,
  "campaign-wizard": CampaignWizard,
  delivery: Delivery,
  "kdm-white": KDM,
  "settings-white": Settings,
  "vendor-white": Vendor,
  "download-white": Download,
  "folder-white": Folder,
  "hardware-white": Hardware,
  "track-upload-white": TrackUpload,
  "print-white": printWhite,
  "network-white": networkWhite
};

const Icon = ({ size, className, icon, reverse, style }) => (
  <div
    style={{
      boxShadow: reverse ? "" : "3px 3px 7px 0 rgba(146,28,49,0.26)",
      height: `${size}px`,
      minHeight: `${size}px`,
      width: `${size}px`,
      minWidth: `${size}px`,
      background:
        icon === "aspera-white"
          ? "linear-gradient(135.28deg, #00B2EF 0%, #61D7FF 100%)"
          : reverse
          ? ""
          : "linear-gradient(-141deg, #FD2A51 26%, #FF4B6D 100%)",
      ...style
    }}
    className={`circle ${className} p1 flex items-center justify-center`}
  >
    <img className="block" src={ICONS[icon]} alt={icon} />
  </div>
);

Icon.defaultProps = {
  size: "48",
  className: "",
  reverse: false,
  style: {}
};

Icon.propTypes = {
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
  icon: PropTypes.string.isRequired,
  reverse: PropTypes.bool,
  style: PropTypes.object
};

export default Icon;
