import React from "react";
import PropTypes from "prop-types";
import { css } from "@emotion/core";
import logo from "@media/logos/svg/blue-red.svg";

const CineSendByBitCine = ({ width }) => (
  <div>
    <img
      alt="CineSend"
      style={{ width: `${width}px` }}
      src={logo}
      className="block"
    />
    <small
      css={css`
        font-size: 9px;
        margin-top: 0.1rem;
      `}
      className="block right-align bold"
    >
      By BitCine
    </small>
  </div>
);

CineSendByBitCine.defaultProps = {
  width: 120
};

CineSendByBitCine.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default CineSendByBitCine;
