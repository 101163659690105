import React from "react";
import PropTypes from "prop-types";
import "whatwg-fetch";
import smoothscroll from "smoothscroll-polyfill";
import { useMount } from "react-use";
import { setConfig } from "react-hot-loader";
import Header from "@app-components/navigation/header/";
import Footer from "@app-components/navigation/footer/";
import PageTitle from "@app-components/utils/page_title";
// import loadFonts from "@helpers/load_fonts";
import Styles from "@styles/";

setConfig({ pureSFC: true });

const Layout = ({ children, page, getStartedText, getStartedButtons }) => {
  useMount(() => {
    // loadFonts();
    smoothscroll.polyfill();
    if (process.env.NODE_ENV !== "development") {
      window.scroll({ top: 0, left: 0, behavior: "smooth" });
    }
  });
  return (
    <React.Fragment>
      <Styles />
      <PageTitle page={page} />
      <Header page={page} />
      {children}
      <Footer
        page={page}
        getStartedText={getStartedText}
        getStartedButtons={getStartedButtons}
      />
    </React.Fragment>
  );
};

Layout.defaultProps = {
  getStartedText: "Sign up today to start managing your content like a pro."
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  page: PropTypes.string.isRequired,
  getStartedText: PropTypes.string,
  getStartedButtons: PropTypes.array.isRequired
};

export default Layout;
