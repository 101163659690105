import React from "react";
import { Global, css } from "@emotion/core";
import toggleCSS from "@styles/toggle";
import landingAnimationCSS from "@styles/landing-animation";
import utilsCSS from "@styles/utils";
import "../../node_modules/cs-theme/dist/index.scss";
import "./gatsby-image.scss";
import "./radar.animation.scss";

const Styles = () => (
  <Global
    styles={css`
      ${toggleCSS}
      ${landingAnimationCSS}
      ${utilsCSS}
    `}
  />
);

export default Styles;
